import React, { FunctionComponent } from "react";
import { useStaticQuery, graphql } from "gatsby";
import MaybeClassProps from "../../components/MaybeClassProps.type";
import Strong from "../../components/Strong";
import Paragraph from "../../components/Paragraph";
import Img from "gatsby-image";
import ImageWrapper from "../../components/ImageWrapper";

const AgingComorbidities: FunctionComponent<MaybeClassProps> = ({
  className,
}) => {
  const data = useStaticQuery(graphql`
    query {
      pillRenalImpairments: file(
        relativePath: {
          eq: "hepatitis-b-management/pill-renal-impairments.png"
        }
      ) {
        ...TextQuery
      }
      pillHypertension: file(
        relativePath: { eq: "hepatitis-b-management/pill-hypertension.png" }
      ) {
        ...TextQuery
      }
      pillHyperlipidemia: file(
        relativePath: { eq: "hepatitis-b-management/pill-hyperlipidemia.png" }
      ) {
        ...TextQuery
      }
      pillDiabetes: file(
        relativePath: { eq: "hepatitis-b-management/pill-diabetes.png" }
      ) {
        ...TextQuery
      }
      pillBoneDiseases: file(
        relativePath: {
          eq: "hepatitis-b-management/pill-osteopenia-and-osteoporosis.png"
        }
      ) {
        ...TextQuery
      }
      comorbidities: file(
        relativePath: { eq: "hepatitis-b-management/comorbidities-graph.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      <div className="pt-4">
        <div className="container">
          <Strong size="lg" color="black-dark">
            Aging and comorbidities
          </Strong>
          <Paragraph>
            In the US, the CHB patient population is growing older and has more
            comorbidities as they age.<sup>7,13</sup>
          </Paragraph>
          <Paragraph>
            Two studies reported the prevalence of comorbidities in US CHB
            patients significantly increased over time (2000-2005 vs 2011-2015
            <sup>7</sup>
            and 2006 vs 2015<sup>13</sup>), including:
          </Paragraph>
          <div className="flex flex-wrap pb-4">
            <div className="w-1/2 md:w-1/3 px-2">
              <Img
                fluid={data.pillRenalImpairments.childImageSharp.fluid}
                className="my-4 mx-2 md:my-8 md:mx-4"
                alt="Renal impairments eg, CKD and ESRD at 2-4 times higher prevalence rates"
              />
            </div>
            <div className="w-1/2 md:w-1/3 px-2">
              <Img
                fluid={data.pillHypertension.childImageSharp.fluid}
                className="my-4 mx-2 md:my-8 md:mx-4"
                alt="Hypertension at 2-3 times higher prevalence rates"
              />
            </div>
            <div className="w-1/2 md:w-1/3 px-2">
              <Img
                fluid={data.pillHyperlipidemia.childImageSharp.fluid}
                className="my-4 mx-2 md:my-8 md:mx-4"
                alt="Hyperlipidemia at 3-5 times higher prevalence rates"
              />
            </div>
            <div className="w-1/2 md:w-1/3 px-2">
              <Img
                fluid={data.pillDiabetes.childImageSharp.fluid}
                className="my-4 mx-2 md:my-8 md:mx-4"
                alt="Diabetes at 1.5-5 times higher prevalence rates"
              />
            </div>
            <div className="w-1/2 md:w-1/3 px-2">
              <Img
                fluid={data.pillBoneDiseases.childImageSharp.fluid}
                className="my-4 mx-2 md:my-8 md:mx-4"
                alt="Osteopenia and osteoporosis at 2-3 times higher prevalence rates"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="py-4 bg-grey md:bg-white">
        <div className="container">
          <div className="md:bg-grey md:mx-32 md:px-4">
            <Strong color="black" className="block py-4">
              Comorbidities in CHB patients during a 15-year period (San
              Francisco Bay Area cohort)<sup>7,i</sup>
            </Strong>
            <ImageWrapper left maxWidth={761}>
              <Img
                fluid={data.comorbidities.childImageSharp.fluid}
                alt="Graph showing comorbidities in CHB patients during a 15 year period (San Francisco Bay Area cohort)"
              />
            </ImageWrapper>
            <div className="text-xs text-black py-4">
              Adapted from Liu et al.{" "}
              <em>Clinical and Translational Gastroenterology</em>.
            </div>
          </div>
          <Paragraph className="text-xs md:pt-4 md:mx-32">
            <sup>i</sup>A retrospective, observational study of 2734 CHB
            patients across 3 time periods (2000–2005; 2006–2010; 2011–2015) at
            a university medical center and primary care clinics in the San
            Francisco Bay Area.<sup>7</sup>
          </Paragraph>
        </div>
      </div>
    </>
  );
};
export default AgingComorbidities;
