import React, { FunctionComponent } from "react";
import MaybeClassProps from "../../components/MaybeClassProps.type";
import Strong from "../../components/Strong";
import Paragraph from "../../components/Paragraph";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Footnotes from "../../components/Footnotes";
import AbbrList from "../../components/Abbr/AbbrList";
import Abbr from "../../components/Abbr/Abbr";
import ImageWrapper from "../../components/ImageWrapper";

const RenalComorbidities: FunctionComponent<MaybeClassProps> = ({
  className,
}) => {
  const data = useStaticQuery(graphql`
    query {
      ckdRisk: file(
        relativePath: { eq: "hepatitis-b-management/ckd-risk-graph.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      esrdRisk: file(
        relativePath: { eq: "hepatitis-b-management/esrd-risk-graph.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      higherPrevalenceCKD: file(
        relativePath: {
          eq: "hepatitis-b-management/higher-prevalence-of-ckd.png"
        }
      ) {
        ...TextQuery
      }
    }
  `);

  return (
    <>
      <div className="pt-4">
        <div className="container">
          <Strong size="lg" color="black-dark">
            Renal-related comorbidities in CHB patients
          </Strong>
          <Paragraph>
            <Strong>Renal function</Strong> typically decreases with age and{" "}
            <Strong>renal impairment</Strong> (eg, CKD, ESRD) is observed more
            frequently in CHB patients than in uninfected people.
            <sup>5,6,14</sup>
          </Paragraph>
          <Paragraph className="pb-8 md:pt-4">
            <Strong>
              Cumulative incidence of CKD and ESRD in untreated, Taiwanese CHB
              cohort<sup>5,6</sup>
            </Strong>
          </Paragraph>
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 md:mx-4">
              <ImageWrapper maxWidth={364}>
                <Img
                  fluid={data.ckdRisk.childImageSharp.fluid}
                  alt="Graph showing patients with HBV have a 3x higher risk for CKD than patients without HBV"
                  imgStyle={{ objectFit: "contain" }}
                />
              </ImageWrapper>
            </div>
            <div className="w-full md:w-1/2 md:mx-4">
              <ImageWrapper maxWidth={364}>
                <Img
                  fluid={data.esrdRisk.childImageSharp.fluid}
                  alt="Graph showing patients with HBV have a 4x higher risk for ESRD than patients without HBV"
                  imgStyle={{ objectFit: "contain" }}
                />
              </ImageWrapper>
            </div>
          </div>
          <Paragraph className="text-xs pb-8">
            Two nationwide, Taiwanese cohort studies using the Taiwan National
            Health Insurance Research Database, to evaluate the association of
            HBV with CKD (inclusive of stages 1 to 5) (1998-2010; N=17,796) or
            ESRD (1999-2010; N=17,758).<sup>5,6</sup>
          </Paragraph>
        </div>
      </div>
      <div className="container">
        <div className="p-4 bg-grey -mx-4 md:mx-0">
          <div className="flex flex-col md:flex-row items-start">
            <div className="w-full md:w-1/2 md:pr-8">
              <ImageWrapper maxWidth={453}>
                <Img
                  fluid={data.higherPrevalenceCKD.childImageSharp.fluid}
                  className="my-8 md:mr-8"
                />
              </ImageWrapper>
            </div>
            <div className="w-full md:w-1/2 md:pt-8">
              <Strong className="block text-center md:text-left">
                1.7x–2.5x higher prevalence of CKD in CHB patients vs.
                uninfected population
                <sup>13,j</sup>
              </Strong>
              <Footnotes>
                <sup>j</sup>A retrospective, observational study with case
                matching of CHB patients without HDV coinfection, based on US
                administrative healthcare claims from Commercial/Medicare
                (n=32,523) and Medicaid (n=11,503) databases from 2006 to 2015.
                <sup>13</sup>
              </Footnotes>
              <AbbrList>
                <Abbr term="CKD" title="chronic kidney disease" />
                <Abbr term="ESRD" title="end stage renal disease" />
                <Abbr term="HDV" title="hepatitis D virus." />
              </AbbrList>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RenalComorbidities;
