import React, { FunctionComponent } from "react";
import { ReactComponent as ChevronIcon } from "./chevron.svg";

type Props = {
  to: string;
  tracking: Function;
  hideArrow?: boolean;
};
const JumpLink: FunctionComponent<Props> = ({
  children,
  to,
  tracking,
  hideArrow,
}) => {
  return (
    <div className="">
      <a href={to} onClick={tracking}>
        <span className="font-bold antialiased text-green underline mr-2">
          {children}
        </span>
        {!hideArrow && (
          <ChevronIcon className="inline-block text-green stroke-current w-4" />
        )}
      </a>
    </div>
  );
};
export default JumpLink;
