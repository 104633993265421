import React, { FunctionComponent } from "react";
import { mergeClassNames } from "../../helpers";
import { ReactComponent as ChevronIcon } from "../../images/icons/chevron.svg";
import { AccordionButtonType } from "./AccordionButtonType";

const TabButton: FunctionComponent<AccordionButtonType> = ({
  children,
  className,
  panel,
  currentPanel,
  setCurrentPanel,
  tracking,
}) => {
  const active = panel === currentPanel;
  return (
    <button
      role="button"
      onClick={onClick}
      className={mergeClassNames(
        className,
        active
          ? "bg-grey text-black"
          : "bg-green hover:bg-green-dark text-white",
        "transition-bg w-full py-2 px-4 flex justify-between items-center mb-1"
      )}
    >
      <div className="text-lg text-left pr-16">{children}</div>
      <ChevronIcon
        className={mergeClassNames(
          active && "hidden",
          "inline w-6 mx-2 md:mx-0 md:w-8 lg:mx-2 lg:w-6"
        )}
      />
    </button>
  );

  function onClick() {
    tracking();
    setCurrentPanel(panel);
  }
};

export default TabButton;
