import React, { FunctionComponent } from "react";
import MaybeClassProps from "../../components/MaybeClassProps.type";
import { ReactComponent as PlusIcon } from "../../images/icons/plus.svg";
import { ReactComponent as MinusIcon } from "../../images/icons/minus.svg";
import { ReactComponent as PlusOrMinusIcon } from "../../images/icons/plus-or-minus.svg";

const MonitoringTable: FunctionComponent<MaybeClassProps> = ({ className }) => {
  return (
    <table className="w-full">
      <tbody>
        <tr>
          <th></th>
          <th></th>
          <th className="w-1/3 h-4 md:h-2"></th>
          <th className="bg-grey-very-light w-1/3">&nbsp;</th>
          <th className="w-4"></th>
        </tr>
        <tr className="bg-red text-white">
          <th className="text-left text-xs md:text-base align-top h-12 pl-4 py-4">
            HBeAg
          </th>
          <th className="text-left text-xs md:text-base align-top h-12 pl-2 py-4">
            HBV DNA (IU/mL)
          </th>
          <th className="text-left text-xs md:text-base align-top h-12 pl-4 py-4 w-1/3">
            ALT
          </th>
          <th className="text-left text-xs md:text-base align-top h-12 pl-4 py-4 bg-red-grey">
            Recommended Follow-up
          </th>
          <th></th>
        </tr>
        <tr>
          <td className="text-center">
            <PlusIcon className="inline" />
          </td>
          <td className="text-xs align-top text-black text-left px-2 py-3">
            &gt;20,000
          </td>
          <td
            rowSpan={2}
            className="text-xs align-top text-black text-left px-2 py-3 border-bottom-grey-light"
          >
            &ge;2xULN or significant histologic disease<sup>c</sup>
          </td>
          <td
            className="text-xs align-top text-black text-left pl-4 pr-2 py-3 bg-grey-very-light border-bottom-grey-light"
            rowSpan={2}
          >
            Treat patients (immune-active CHB)
          </td>
          <td className="w-4"></td>
        </tr>
        <tr className="border-bottom-grey-light">
          <td className="text-center">
            <MinusIcon className="inline" />
          </td>
          <td className="text-xs align-top text-black text-left px-2 py-3">
            &gt;2,000
          </td>
          <td className="w-4"></td>
        </tr>
        <tr className="border-bottom-grey-light">
          <td className="text-center">
            <PlusOrMinusIcon className="inline" />
          </td>
          <td className="text-xs align-top text-black text-left px-2 py-3">
            Detectable
          </td>
          <td className="text-xs align-top text-black text-left px-2 py-3">
            Cirrhosis, regardless of ALT
          </td>
          <td className="text-xs align-top text-black text-left pl-4 pr-2 py-3 bg-grey-very-light">
            Treat patients
          </td>
          <td className="w-4"></td>
        </tr>
        <tr>
          <td className="text-center align-top pt-2">
            <PlusIcon className="inline" />
          </td>
          <td className="text-xs align-top text-black text-left px-2 py-3">
            &le;20,000
          </td>
          <td
            rowSpan={2}
            className="text-xs align-top text-black text-left px-2 py-3"
          >
            &lt;2xULN
          </td>
          <td
            rowSpan={3}
            className="text-xs align-top text-black text-left pl-4 pr-2 py-3 bg-grey-very-light"
          >
            Consider treatment based on:
            <ul>
              <li className="ml-4">Age (&gt;40 years)</li>
              <li className="ml-4">Family history of cirrhosis or HCC</li>
              <li className="ml-4">
                Previous treatment history<sup>d</sup>
              </li>
              <li className="ml-4">
                Extrahepatic manifestations<sup>e</sup>
              </li>
            </ul>
          </td>
          <td className="w-4"></td>
        </tr>
        <tr>
          <td className="text-center align-top pt-2">
            <MinusIcon className="inline" />
          </td>
          <td className="text-xs align-top text-black text-left px-2 py-3">
            &le;2,000
          </td>
          <td className="w-4"></td>
        </tr>
        <tr>
          <td className="text-center align-top pt-2"></td>
          <td className="text-xs align-top text-black text-left px-2 py-3"></td>
          <td className="w-4"></td>
        </tr>
      </tbody>
    </table>
  );
};
export default MonitoringTable;
