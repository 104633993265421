import React, { FunctionComponent } from "react";
import Mobile from "./Mobile";
import Desktop from "./Desktop";

const UltrasoundSurveillanceTables: FunctionComponent = () => {
  return (
    <>
      <div className="block sm:hidden">
        <Mobile />
      </div>
      <div className="hidden sm:block">
        <Desktop />
      </div>
    </>
  );
};
export default UltrasoundSurveillanceTables;
