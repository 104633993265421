import React, { FunctionComponent } from "react";
import MaybeClassProps from "../../components/MaybeClassProps.type";
import Strong from "../../components/Strong";
import Paragraph from "../../components/Paragraph";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Footnotes from "../../components/Footnotes";
import Abbr from "../../components/Abbr/Abbr";
import ImageWrapper from "../../components/ImageWrapper";

const BoneComorbidities: FunctionComponent<MaybeClassProps> = ({
  className,
}) => {
  const data = useStaticQuery(graphql`
    query {
      osteopenia: file(
        relativePath: { eq: "hepatitis-b-management/Osteopenia-graph.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      osteoporosis: file(
        relativePath: { eq: "hepatitis-b-management/Osteoporosis-graph.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      higherPrevalenceOsteoporosis: file(
        relativePath: {
          eq: "hepatitis-b-management/higher-prevalence-of-osteoporosis.png"
        }
      ) {
        ...TextQuery
      }
    }
  `);

  return (
    <>
      <div className="pt-4">
        <div className="container">
          <Strong size="lg" color="black-dark">
            Bone-related comorbidities in CHB patients
          </Strong>
          <Paragraph>
            <Strong>Bone density</Strong> may decrease with age, and{" "}
            <Strong>osteoporosis and bone fracture</Strong> are observed more
            frequently in CHB patients than in uninfected people.
            <sup>13,15</sup>
          </Paragraph>
          <Paragraph className="pb-8">
            <Strong>
              Prevalence of osteopenia and osteoporosis in a Chinese cohort
              <sup>15,k</sup>
            </Strong>
          </Paragraph>
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 md:mx-4">
              <ImageWrapper maxWidth={366}>
                <Img
                  fluid={data.osteopenia.childImageSharp.fluid}
                  alt="Graph showing prevalence of osteopenia in a Chinese cohort"
                  imgStyle={{ objectFit: "contain" }}
                />
              </ImageWrapper>
            </div>
            <div className="w-full md:w-1/2 md:mx-4">
              <ImageWrapper maxWidth={366}>
                <Img
                  fluid={data.osteoporosis.childImageSharp.fluid}
                  alt="Graph showing prevalence of osteoporosis in a Chinese cohort"
                  imgStyle={{ objectFit: "contain" }}
                />
              </ImageWrapper>
            </div>
          </div>
          <Paragraph className="text-xs pb-8">
            <sup>k</sup>A study conducted in China (2014-2015) of 148 CHB
            patients vs age- and gender-matched healthy controls, to investigate
            the prevalence of osteoporosis in CHB patients.<sup>16</sup>
          </Paragraph>
        </div>
      </div>
      <div className="container">
        <div className="p-4 bg-grey -mx-4 md:mx-0">
          <div className="flex flex-col md:flex-row items-start">
            <div className="w-full md:w-1/2">
              <ImageWrapper maxWidth={453}></ImageWrapper>
              <Img
                fluid={data.higherPrevalenceOsteoporosis.childImageSharp.fluid}
                className="my-8 md:mr-8"
              />
            </div>
            <div className="w-full md:w-1/2 md:pt-8">
              <Strong className="block text-center md:text-left">
                In the US, prevalence of osteoporosis and/or bone fracture in
                CHB patients is up to 1.5x higher vs. uninfected population
                <sup>13,l</sup>
              </Strong>
              <Footnotes>
                <sup>l</sup>A retrospective, observational study with case
                matching of CHB patients without HDV coinfection, based on US
                administrative healthcare claims from Commercial/Medicare
                (n=32,523) and Medicaid (n=11,503) databases from 2006 to 2015.
                <sup>13</sup>
                <br />
              </Footnotes>
              <Footnotes>
                <Abbr term="HDV" title="hepatitis D virus." />
              </Footnotes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BoneComorbidities;
