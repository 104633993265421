import React, { useState, FunctionComponent, useEffect } from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import HeroImage from "../components/HeroImage/HeroImage";
import Slab from "../components/Slab";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import heroBox from "../images/hepatitis-b-management/hero-box.svg";
import heroBoxWithoutAnimation from "../images/hepatitis-b-management/hero-box-without-animation.svg";
import Subheading from "../components/Subheading/Subheading";
import HorizontalRule from "../components/HorizontalRule";
import Strong from "../components/Strong";
import Paragraph from "../components/Paragraph";
import JumpLink from "../components/Link/JumpLink";
import Card from "../components/Card/Card";
import DonutGraph from "../components/DonutGraph/DonutGraph";
import { ReactComponent as InfoIcon } from "../images/icons/info.svg";

import { ReactComponent as ChatIcon } from "../images/icons/chat.svg";
import { ReactComponent as MedicineIcon } from "../images/icons/medicine.svg";
import { ReactComponent as PillsIcon } from "../images/icons/pills.svg";
import { ReactComponent as CalendarIcon } from "../images/icons/calendar.svg";
import { ReactComponent as LifestyleIcon } from "../images/icons/lifestyle.svg";

import NextPage from "../components/Link/NextPage";
import AssetCardGroup from "../components/AssetCard/AssetCardGroup";
import ScreeningGuidelines from "../partials/AssetCards/ScreeningGuidelines";
import UnmetNeeds from "../partials/AssetCards/UnmetNeeds";
import References from "../components/References/References";
import MonitoringTable from "../partials/Tables/MonitoringTable";
import UltrasoundSurveillanceTables from "../partials/Tables/UltrasoundSurveillanceTables";

import { Accordion, AccordionItem, AccordionPanel } from "@reach/accordion";
import "@reach/accordion/styles.css";
import AccordionButton from "../components/Accordion/AccordionButton";
import AgingComorbidities from "../partials/Tabs/AgingComorbidities";
import RenalComorbidities from "../partials/Tabs/RenalComorbidities";
import BoneComorbidities from "../partials/Tabs/BoneComorbidities";
import TabButton from "../components/Accordion/TabButton";
import { isMobile } from "../helpers";
import Footnotes from "../components/Footnotes";
import Abbr from "../components/Abbr/Abbr";
import AbbrList from "../components/Abbr/AbbrList";
import ReferenceItem from "../components/References/ReferenceItem";
import tracker from "../tracker";
import ImageWrapper from "../components/ImageWrapper";
import Parallax from "../components/Parallax/Parallax";

// type WildcardRouteProps = {
//   "*": string;
// };

// const childRoutes = [
//   "aging-and-comorbidities",
//   "renal-related-comorbidities",
//   "bone-related-comorbidities",
// ];
//const ManagingHepB: FunctionComponent<WildcardRouteProps> = ({ "*": slug }) => {

const ManagingHepB: FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "hepatitis-b-management/hero.jpg" }) {
        ...HeroQuery
      }
      slabPerson: file(
        relativePath: { eq: "hepatitis-b-management/5-0-slab-person.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 236) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      pillSurvival: file(
        relativePath: { eq: "hepatitis-b-management/pill-survival.png" }
      ) {
        ...TextQuery
      }
      pillTumorDetection: file(
        relativePath: { eq: "hepatitis-b-management/pill-tumor-detection.png" }
      ) {
        ...TextQuery
      }
      slabPeople: file(
        relativePath: { eq: "hepatitis-b-management/5-0-slab-people.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 467) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      footerMan: file(relativePath: { eq: "footer-man.png" }) {
        childImageSharp {
          fluid(maxWidth: 328) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      page: trackingJson(id: { eq: "hepatitis-b-management" }) {
        subject
      }
      seeMonitoringRecommendations: trackingJson(
        id: { eq: "seeMonitoringRecommendations" }
      ) {
        ...TrackingQuery
      }
      learnHowToSupport: trackingJson(id: { eq: "learnHowToSupport" }) {
        ...TrackingQuery
      }
      exploreTheImpact: trackingJson(id: { eq: "exploreTheImpact" }) {
        ...TrackingQuery
      }
      agingAndComorbidities: trackingJson(id: { eq: "agingAndComorbidities" }) {
        ...TrackingQuery
      }
      renalRelatedComorbidities: trackingJson(
        id: { eq: "renalRelatedComorbidities" }
      ) {
        ...TrackingQuery
      }
      boneRelatedComorbidities: trackingJson(
        id: { eq: "boneRelatedComorbidities" }
      ) {
        ...TrackingQuery
      }
      educationalResources: trackingJson(id: { eq: "educationalResources" }) {
        ...TrackingQuery
      }
      downloadTheGuide: trackingJson(id: { eq: "downloadTheGuide" }) {
        ...TrackingQuery
      }
      viewFlashcard: trackingJson(id: { eq: "viewFlashcard" }) {
        ...TrackingQuery
      }
    }
  `);

  const defaultPanel = 0;
  const [currentPanel, setCurrentPanel] = useState(
    //slug !== "" ? childRoutes.indexOf(slug) : defaultPanel
    0
  );

  useEffect(() => {
    window.dataLayer.push(data.page);
    setCurrentPanel(isMobile() ? -1 : 0);
  }, []);

  function onChangePanel(index: number, preventCollapse?: boolean) {
    if (index === currentPanel && !preventCollapse) {
      return setCurrentPanel(-1);
    }
    setCurrentPanel(index);
  }

  const accordionProps = {
    currentPanel,
    setCurrentPanel: (index: number) => onChangePanel(index, false),
  };
  const tabButtonProps = {
    currentPanel,
    setCurrentPanel: (index: number) => onChangePanel(index, true),
  };
  return (
    <Layout>
      <SEO
        title="Management of Chronic Hepatitis B"
        description="Learn why monitoring chronic hepatitis B patients is important for liver damage and what you can do to help support treatment adherence."
      />
      <HeroImage
        headLine="Managing chronic hepatitis B."
        content={
          <span>
            Chronic hepatitis B (CHB) is a dynamic disease. Ongoing and
            consistent monitoring is an essential component of CHB management.
            It is also critical to remain vigilant about regular screening for
            liver damage and liver cancer, and to routinely monitor patients on
            CHB treatment for possible nonadherence or the development of
            resistance.<sup>1</sup>
          </span>
        }
        fluidQuery={data.hero.childImageSharp.fluid}
        boxSrc={heroBox}
        boxSrcWithoutAnimation={heroBoxWithoutAnimation}
        imagePosition={{ desktop: { x: 60, y: 25 }, mobile: { x: 70, y: 50 } }}
      />
      <Slab className="bg-grey">
        <div className="md:flex">
          <div className="w-full md:w-1/3 pb-8 pr-8">
            <Strong color="black-dark" size="lg">
              Monitoring CHB patients
            </Strong>
            <Paragraph className="pb-4">
              Poor monitoring of CHB patients makes determining treatment
              eligibility more difficult, while suboptimal surveillance
              predisposes CHB patients to the risks of disease progression,
              including HCC and its sequelae.<sup>2-4</sup>
            </Paragraph>
            <JumpLink
              to="#monitoring"
              tracking={tracker(data.seeMonitoringRecommendations)}
            >
              See monitoring recommendations
            </JumpLink>
          </div>
          <div className="w-full md:w-1/3 pb-8 pr-8">
            <Strong color="black-dark" size="lg">
              Supporting treatment adherence
            </Strong>
            <Paragraph className="pb-4">
              Adherence to CHB treatment and regular follow-up visits are
              essential for optimal patient health.
            </Paragraph>
            <JumpLink to="#support" tracking={tracker(data.learnHowToSupport)}>
              Learn how to support adherence
            </JumpLink>
          </div>
          <div className="w-full md:w-1/3 pb-8">
            <Strong color="black-dark" size="lg">
              Assessing comorbidities
            </Strong>
            <Paragraph className="pb-4">
              People with CHB have a significantly higher prevalence—and risk—of
              renal disease, and a higher prevalence of cardiovascular, bone,
              and metabolic diseases than those without CHB.<sup>5-7</sup>
            </Paragraph>
            <JumpLink
              to="#consider-the-impact"
              tracking={tracker(data.exploreTheImpact)}
            >
              Explore the impact on patients
            </JumpLink>
          </div>
        </div>
        <Footnotes>
          <Abbr term="HCC" title="hepatocellular carcinoma." />
        </Footnotes>
      </Slab>
      <Slab name="monitoring" paddingBottom="pb-0">
        <Subheading>Lifelong monitoring for CHB patients</Subheading>
        <Paragraph className="pb-8">
          Many people with CHB receive suboptimal care and are not adequately
          monitored for ALT and HBV DNA, leaving them at risk for cirrhosis and
          HCC.<sup>2,3</sup>
        </Paragraph>
        <Card className="bg-grey">
          <div className="flex flex-col md:flex-row px-4 pb-8 pt-4">
            <div className="w-full md:w-1/4">
              <Strong
                color="black-dark"
                size="lg"
                className="block py-4 text-center md:text-left"
              >
                CHB patients who are either never or not routinely monitored for
                <sup>2,a</sup>:
              </Strong>
            </div>
            <div className="w-full md:w-1/4">
              <div className="w-full flex flex-col justify-between text-center h-full pb-8 md:pb-0 border-b md:border-b-0 md:border-r border-grey-light">
                <Strong color="red" size="lg">
                  ALT
                </Strong>
                <DonutGraph
                  className="px-8 -mb-4 md:mb-0"
                  percent={0.22}
                  alt="Donut graph showing 22%"
                  maxWidth={135}
                />
              </div>
            </div>
            <div className="w-full md:w-1/4 ">
              <div className="w-full flex flex-col justify-between text-center h-full pt-4 pb-8 md:pb-0 md:pt-0 border-b md:border-b-0 md:border-r border-grey-light">
                <Strong color="red" size="lg">
                  HBV DNA
                </Strong>
                <DonutGraph
                  className="px-8 -mb-4 md:mb-0"
                  percent={0.62}
                  alt="Donut graph showing 62%"
                  maxWidth={135}
                />
              </div>
            </div>
            <div className="w-full md:w-1/4">
              <div className="w-full flex flex-col justify-between text-center h-full pt-4 md:pt-0">
                <Strong color="red" size="lg" className="px-2">
                  Hepatic Imaging in patients with cirrhosis
                </Strong>
                <DonutGraph
                  className="px-8 -mb-4 md:mb-0"
                  percent={0.86}
                  alt="Donut graph showing 86%"
                  maxWidth={135}
                />
              </div>
            </div>
          </div>
          <Footnotes>
            <sup>a</sup>This was a multicenter observational study of the
            Chronic Hepatitis Cohort Study (CHeCS), which included 2338 patients
            in the US with confirmed CHB.<sup>2</sup>
          </Footnotes>
        </Card>
      </Slab>
      <Slab paddingBottom="pb-0">
        <Subheading>
          Every 3-12 months<sup>b</sup>
        </Subheading>
        <Paragraph>
          <Strong color="black-dark" size="lg">
            AASLD 2018 guidance recommends blood tests to monitor HBeAg, HBV
            DNA, and ALT levels<sup>1</sup>
          </Strong>
        </Paragraph>
        <MonitoringTable />
        <AbbrList>
          <Abbr term="ALT" title="alanine aminotransferase" />
          <Abbr term="HBeAg" title="hepatitis B e-antigen" />
          <Abbr
            term="HBV DNA"
            title="hepatitis B virus
          deoxyribonucleic acid."
          />
        </AbbrList>
        <Footnotes>
          {" "}
          <sup>b</sup>At the clinician’s discretion. <br />
          <sup>c</sup>Liver biopsy/non-invasive testing showing
          moderate-to-severe necroinflammatory activity on histology and/or
          significant fibrosis.
          <br />
          <sup>d</sup>Serologic and virologic benefits of pegylated interferon
          occur after treatment discontinuation (delayed). Prior exposure to
          nucleos(t)ide analogs is a risk for drug resistance.
          <br />
          <sup>e</sup>Presence of extrahepatic manifestations is an indication
          for treatment, independent of liver disease severity.
        </Footnotes>
        <div className="flex flex-col sm:flex-row-reverse pt-12">
          <div className="w-full sm:w-3/4">
            <Card className="bg-grey">
              <div className="flex flex-col md:flex-row">
                <div className="w-full md:w-1/3 text-center md:text-left">
                  <Strong color="black-dark" size="lg" className="block p-2">
                    CHB patients who never undergo, or receive suboptimal, HCC
                    surveillance<sup>3,f</sup>:
                  </Strong>
                </div>
                <div className="w-full md:w-2/3">
                  <div className="flex">
                    <div className="w-1/2 text-center">
                      <DonutGraph
                        percent={0.78}
                        className="px-4"
                        alt="Donut graph showing 78%"
                        maxWidth={135}
                      />
                      <div className="uppercase text-grey-dark sm:px-8">
                        Patients without cirrhosis
                      </div>
                    </div>
                    <div className="w-1/2 text-center">
                      <DonutGraph
                        percent={0.62}
                        className="px-4"
                        alt="Donut graph showing 62%"
                        maxWidth={135}
                      />
                      <div className="uppercase text-grey-dark sm:px-8">
                        Patients with cirrhosis
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <Paragraph className="text-xs pb-4">
              <sup>f</sup>This was a retrospective analysis of 1329 CHB patients
              from the San Francisco Bay Area (January 1996-July 2013).
              <sup>3</sup>
            </Paragraph>
          </div>
          <div className="w-full sm:w-1/4 pt-4 px-24 sm:px-0 md:px-4 self-end">
            <ImageWrapper maxWidth={236}>
              <Img fluid={data.slabPerson.childImageSharp.fluid} />
            </ImageWrapper>
          </div>
        </div>
        <HorizontalRule className="border-grey-light" />
      </Slab>
      <Slab>
        <div className="md:flex">
          <div className="w-full md:w-1/4 pr-8">
            <Subheading className="w-full">Every 6 months</Subheading>
            <Paragraph>
              <Strong color="black-dark" size="lg">
                AASLD and AATA recommend ultrasound ± AFP for HCC surveillance
                with at-risk patients<sup>1</sup>
              </Strong>
            </Paragraph>
          </div>
          <div className="w-full md:w-3/4">
            <UltrasoundSurveillanceTables />
          </div>
        </div>
        <AbbrList>
          <Abbr
            term="AASLD"
            title="American Association for the Study of Liver Diseases"
          />
          <Abbr term="AATA" title="Asian American Treatment Algorithm" />
          <Abbr term="AFP" title="alpha-fetoprotein" />
          <Abbr term="HBsAg" title="hepatitis B surface antigen." />
        </AbbrList>
        <Footnotes>
          <sup>g</sup>Considered at high risk. Low- to moderate-risk patients
          such as low replication and immune tolerant should receive
          surveillance every 6-12 months. Patients who clear HBsAg may still
          develop HCC, and should continue surveillance.
          <sup>8</sup>
          <br />
          <sup>h</sup>AFP and CT scan or MRI at 3 months for people with
          elevated AFP or undergoing HCC treatment.
        </Footnotes>
        <HorizontalRule className="border-grey-light mt-4 mb-16" />
        <Strong color="black-dark" size="lg">
          With routine surveillance:
        </Strong>
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 md:pr-10 lg:pr-32">
            <ImageWrapper maxWidth={500}>
              <Img
                fluid={data.pillTumorDetection.childImageSharp.fluid}
                alt="About two times improvement in early tumor detection"
                className="my-6 mt-8"
              />
              <Img
                fluid={data.pillSurvival.childImageSharp.fluid}
                alt="About-two-times-improvement-three-year-survival"
                className="my-6"
              />
            </ImageWrapper>
          </div>
          <div className="w-full self-end md:w-1/2 md:pl-16 lg:pl-32 pr-4 -mb-10">
            <Parallax speed={-1} percentage={0.2}>
              <ImageWrapper maxWidth={436}>
                <Img fluid={data.slabPeople.childImageSharp.fluid} />
              </ImageWrapper>
            </Parallax>
          </div>
        </div>
      </Slab>
      <Slab className="bg-green relative">
        <div className="px-6">
          <InfoIcon className="mb-4" />
          <Strong color="white" size="lg">
            HCC surveillance is recommended for many CHB patients, especially
            those with cirrhosis<sup>1,8</sup>
          </Strong>
        </div>
      </Slab>
      <Slab name="support" className="bg-grey">
        <Subheading>
          Support patients&rsquo; adherence to CHB treatment
        </Subheading>
        <Paragraph className="pb-10">
          Compared with clinical trials, treatment adherence may be lower in
          clinical practice. Patients may be less motivated, less frequently
          monitored, and often have to pay a part of their medication costs.
          <sup>10</sup>
        </Paragraph>
        <Strong color="black-dark" size="lg">
          Discussing treatment adherence with your CHB patients
        </Strong>
        <div className="flex flex-col md:flex-row md:flex-wrap">
          <div className="w-full md:w-1/3 py-4 pr-6">
            <ChatIcon className="h-20" />
            <Paragraph>
              <Strong>Engage in an open dialogue with your patients</Strong> and
              listen carefully to their concerns. Encouraging your patients to
              express what is on their minds may help them become more involved
              in and committed to their chronic hepatitis B treatment plan.
            </Paragraph>
          </div>
          <div className="w-full md:w-1/3 py-4 pr-6">
            <MedicineIcon className="h-20" />
            <Paragraph>
              <Strong>Advise patients to check with you</Strong> before taking
              any new medications, over-the-counter medicines, or herbal
              supplements, and to let you know if they experience any side
              effects.
              <sup>11</sup>
            </Paragraph>
          </div>
          <div className="w-full md:w-1/3 py-4 pr-6">
            <PillsIcon className="h-20" />
            <Paragraph>
              <Strong>
                Urge your patients to take their chronic hepatitis B medicine
                exactly as prescribed.{" "}
              </Strong>
              Explain to your patients that they should not stop taking their
              chronic hepatitis B medicine without first discussing it with you.
              <sup>11</sup>
            </Paragraph>
          </div>
          <div className="w-full md:w-1/3 py-4 pr-6">
            <CalendarIcon className="h-20" />
            <Paragraph>
              <Strong>Suggest medication reminder tools,</Strong> such as a cell
              phone app, an alarm clock, or a 7-day pill box, for those patients
              who are prescribed oral antivirals and need help remembering to
              take them.
            </Paragraph>
          </div>
          <div className="w-full md:w-1/3 py-4 pr-6">
            <LifestyleIcon className="h-20" />
            <Paragraph>
              <Strong>Encourage lifestyle changes for liver health,</Strong>{" "}
              such as avoiding alcohol and smoking and eating a healthy diet low
              in saturated fats, with plenty of fruits and vegetables.
              <sup>12</sup>
            </Paragraph>
          </div>
        </div>
      </Slab>
      <Slab name="consider-the-impact">
        <Subheading>
          Consider the impact of comorbidities on CHB patients
        </Subheading>
        <Paragraph>
          Due to the associations between CHB infection and comorbidities such
          as kidney- and bone-related conditions, careful evaluation and
          consideration are needed when managing CHB patients.<sup>1</sup>
        </Paragraph>
      </Slab>
      <div className="container">
        <div className="hidden md:flex">
          <div className="w-1/3 pr-1">
            <TabButton
              panel={0}
              {...tabButtonProps}
              tracking={tracker(data.agingAndComorbidities)}
            >
              Aging and comorbidities
            </TabButton>
          </div>
          <div className="w-1/3 pr-1">
            <TabButton
              panel={1}
              {...tabButtonProps}
              tracking={tracker(data.renalRelatedComorbidities)}
            >
              Renal-related comorbidities
            </TabButton>
          </div>
          <div className="w-1/3">
            <TabButton
              panel={2}
              {...tabButtonProps}
              tracking={tracker(data.boneRelatedComorbidities)}
            >
              Bone-related comorbidities
            </TabButton>
          </div>
        </div>
      </div>
      <Accordion index={currentPanel} onChange={onChangePanel}>
        <AccordionItem>
          <AccordionButton
            panel={0}
            {...accordionProps}
            className="block md:hidden"
            tracking={tracker(data.agingAndComorbidities)}
          >
            Aging and comorbidities
          </AccordionButton>
          <AccordionPanel>
            <AgingComorbidities />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton
            panel={1}
            {...accordionProps}
            className="block md:hidden"
            tracking={tracker(data.renalRelatedComorbidities)}
          >
            Renal-related comorbidities
          </AccordionButton>
          <AccordionPanel>
            <RenalComorbidities />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton
            panel={2}
            {...accordionProps}
            className="block md:hidden"
            tracking={tracker(data.boneRelatedComorbidities)}
          >
            Bone-related comorbidities
          </AccordionButton>
          <AccordionPanel>
            <BoneComorbidities />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Slab>
        <NextPage
          to="/hepatitis-b-management/patient-education"
          tracking={tracker(data.educationalResources)}
        >
          Educational resources for your chronic hepatitis B patients
        </NextPage>
        <Subheading level={3}>
          Information for healthcare professionals
        </Subheading>
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-3/4">
            <AssetCardGroup>
              <ScreeningGuidelines
                className="lg:w-1/2"
                tracking={tracker(data.downloadTheGuide)}
              />
              <UnmetNeeds
                className="lg:w-1/2"
                cta="View Flashcard"
                tracking={tracker(data.viewFlashcard)}
              />
            </AssetCardGroup>
            <AbbrList>
              <Abbr
                term="AASLD"
                title="American Association for the Study of Liver Diseases"
              />
              <Abbr term="ACP" title="American College of Physicians" />
              <Abbr
                term="CDC"
                title="Centers for Disease Control and Prevention"
              />
              <Abbr
                term="USPSTF"
                title="U.S. Preventive Services Task Force."
              />
            </AbbrList>
            <References>
              <ReferenceItem>
                . Terrault N, Lok A, McMahon B, et al. Update on prevention,
                diagnosis, and treatment of chronic hepatitis B: AASLD 2018
                hepatitis B guidance. <em>Hepatology</em>. 2018;67(4):1560-1599.
              </ReferenceItem>
              <ReferenceItem>
                Spradling P, Xing J, Rupp, L et al. Infrequent clinical
                assessment of chronic hepatitis B patients in United States
                general healthcare settings. <em>Clin Infect Dis</em>.
                2016;63:1205-1208.
              </ReferenceItem>
              <ReferenceItem>
                Wang C, Chen V, Vu V, et al. Poor adherence and low persistency
                rates for hepatocellular carcinoma surveillance in patients with
                chronic hepatitis B. Medicine (Baltimore). 2016;95:e4744.
              </ReferenceItem>
              <ReferenceItem>
                Centers for Disease Control and Prevention.{" "}
                <em>MMWR Morb Mortal Wkly Rep</em>. 2008;57:1-20.
              </ReferenceItem>
              <ReferenceItem>
                Chen YC,et al. 13-year nationwide cohort study of chronic kidney
                disease risk among treatment-naïve patients with chronic
                hepatitis B in Taiwan. <em>BMC Nephrology</em>. 2015;16:110.
              </ReferenceItem>
              <ReferenceItem>
                Chen Y, Su Y, Li C, et al. A nationwide cohort study suggests
                chronic hepatitis B virus infection increases the risk of
                end-stage renal disease among patients in Taiwan.{" "}
                <em>Kidney Int</em>. 2015;87:1030-1038.
              </ReferenceItem>
              <ReferenceItem>
                Liu A, Le A, Zhang J, et al. Increasing co-morbidities in
                chronic hepatitis B patients: experience in primary care and
                referral practices during 2000-2015.{" "}
                <em>Clin Transl Gastroenterol</em>. 2018;9(3):141.
              </ReferenceItem>
              <ReferenceItem>
                Tong M, Pan C, Han S, et al. An expert consensus for the
                management of chronic hepatitis B in Asian Americans.{" "}
                <em>Aliment Pharmacol Ther</em>. 2018;47(8):1181-1200.
              </ReferenceItem>
              <ReferenceItem>
                Singal A, Pillai A, Tiro J. Early detection, curative treatment,
                and survival rates for hepatocellular carcinoma surveillance in
                patients with cirrhosis: a meta-analysis. <em>PLOS Med</em>.
                2014;11:e1001624.
              </ReferenceItem>
              <ReferenceItem>
                Scaglione SJ, Lok ASF. Effectiveness of hepatitis B treatment in
                clinical practice. <em>Gastroenterology</em>.
                2012;142(6):1360-1368.e1.
              </ReferenceItem>
              <ReferenceItem>
                National Institutes of Health. U.S. National Library of
                Medicine. Hepatitis B.
                https://medlineplus.gov/ency/article/000279.htm. Last updated
                October 2, 2019. Accessed October 19, 2019.
              </ReferenceItem>
              <ReferenceItem>
                Hepatitis B Foundation. Adults living with hepatitis B: Healthy
                Liver Tips. Hepatitis B Foundation website.
                https://www.hepb.org/treatment-and-management/adults-with-hepatitis-b/healthy-liver-tips.
                Accessed October 19, 2019.
              </ReferenceItem>
              <ReferenceItem>
                Nguyen M, Lim J, Burak Ozbay A, et al. Advancing age and
                comorbidity in a US insured population-based cohort of patients
                with chronic hepatitis B. <em>Hepatology</em>.
                2019;69(3):959-973.
              </ReferenceItem>
              <ReferenceItem>
                National Kidney Foundation. Clinical practice guidelines: For
                chronic kidney disease: evaluation, classification and
                stratification. <em>Am J Kidney Dis</em>. 2002;39(2Suppl
                1):S1-S266.
              </ReferenceItem>
              <ReferenceItem>
                Demontiero O, Vidal C, Duque G, et al. Aging and bone loss: new
                insights for the clinician. <em>Ther Adv Musculoskelet Dis</em>.
                2012;4:61-76.
              </ReferenceItem>
              <ReferenceItem>
                Huang Z, Wei H, Cheng C, et al. Low bone mineral density in
                chronic hepatitis B virus infection: A case-control study.{" "}
                <em>Pak J Med Sci</em>. 2017;33:457-461.  
              </ReferenceItem>
            </References>
          </div>
          <div className="w-full md:w-1/4 self-end h-48 md:h-auto md:ml-8 pt-8">
            <div className="z:0 -mb-64 md:-mb-16 mx-16 sm:mx-32 md:mx-0">
              <ImageWrapper maxWidth={328}>
                <Img fluid={data.footerMan.childImageSharp.fluid} />
              </ImageWrapper>
            </div>
          </div>
        </div>
      </Slab>
    </Layout>
  );
};
export default ManagingHepB;
