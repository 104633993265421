import React, { FunctionComponent } from "react";
import { mergeClassNames } from "../../helpers";
import { AccordionButton as ReachButton } from "@reach/accordion";
import { ReactComponent as ChevronIcon } from "../../images/icons/chevron.svg";
import { AccordionButtonType } from "./AccordionButtonType";
import "./AccordionButton.scss";
const AccordionButton: FunctionComponent<AccordionButtonType> = ({
  children,
  className,
  panel,
  currentPanel,
  setCurrentPanel,
  tracking,
}) => {
  const active = panel === currentPanel;
  return (
    <ReachButton
      onClick={onClick}
      className={mergeClassNames(
        className,
        "transition-bg bg-green hover:bg-green-dark text-white w-full py-2 px-6 flex justify-between items-center mb-1 h-24 AccordionButton"
      )}
    >
      <div className="text-lg text-left pr-16">{children}</div>
      <div
        className={mergeClassNames(
          active ? "flex-row-reverse" : "flex-row",
          "transition underline text-xs flex items-center"
        )}
      >
        {active ? "close" : "More"}
        <ChevronIcon
          className={mergeClassNames(
            active ? "rotate-180" : "",
            "transform inline w-6 mx-2 AccordionButton__Icon"
          )}
        />
      </div>
    </ReachButton>
  );

  function onClick() {
    tracking();
    setCurrentPanel(panel);
  }
};

export default AccordionButton;
