import React, { FunctionComponent } from "react";
import MaybeClassProps from "../../../components/MaybeClassProps.type";
import Table, {
  TableRow,
  TableCell,
  TableHeader,
} from "../../../components/Table/Table";
import { mergeClassNames } from "../../../helpers";

const UltrasoundSurveilanceTablesMobile: FunctionComponent<MaybeClassProps> = ({
  className,
}) => {
  return (
    <>
      <Table grid className={mergeClassNames(className, "mt-6")}>
        <TableRow header>
          <TableHeader colSpan={2}>
            AASLD<sup>1</sup>
          </TableHeader>
        </TableRow>
        <TableRow>
          <TableHeader className="text-red">Tests</TableHeader>
          <TableHeader className="text-red">At-risk populations</TableHeader>
        </TableRow>
        <TableRow>
          <TableCell>
            <ul>
              <li className="pb-4">Ultrasound ± AFP</li>
              <li className="pb-4">Every 6 months</li>
            </ul>
          </TableCell>
          <TableCell>
            <ol className="list-decimal list-inside">
              <li className="pb-4">All HBsAg+ persons with cirrhosis</li>
              <li className="pb-4">People with a family history of HCC</li>
              <li className="pb-4">People with hepatitis D virus infection</li>
              <li className="pb-4">
                HBsAg+ Asian or African American men &gt;40 years of age
              </li>
              <li>HBsAg+ Asian women &gt;50 years of age</li>
            </ol>
          </TableCell>
        </TableRow>
      </Table>
      <Table grid className={mergeClassNames(className, "mt-4")}>
        <TableRow header>
          <TableHeader colSpan={2}>
            AATA<sup>8</sup>
          </TableHeader>
        </TableRow>
        <TableRow>
          <TableHeader className="text-red">Tests</TableHeader>
          <TableHeader className="text-red text-left">
            <div className="pl-2">
              At-risk populations
              <br />
              (Asian Americans)<sup>g</sup>
            </div>
          </TableHeader>
        </TableRow>
        <TableRow>
          <TableCell>
            <ul>
              <li className="pb-4">
                Ultrasound and AFP<sup>h</sup>
              </li>
              <li className="pb-4">Every 6 months</li>
            </ul>
          </TableCell>
          <TableCell>
            <ol className="list-decimal list-inside">
              <li className="pb-4">All HBsAg+ people with chronic hepatitis</li>
              <li className="pb-4">
                People with cirrhosis or family history of HCC
              </li>
            </ol>
          </TableCell>
        </TableRow>
      </Table>
    </>
  );
};
export default UltrasoundSurveilanceTablesMobile;
