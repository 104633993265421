import React, { FunctionComponent } from "react";
import MaybeClassProps from "../../../components/MaybeClassProps.type";
import Table, {
  TableRow,
  TableCell,
  TableHeader,
} from "../../../components/Table/Table";
import { mergeClassNames } from "../../../helpers";

const UltrasoundSurveillanceTablesDesktop: FunctionComponent<MaybeClassProps> = ({
  className,
}) => {
  return (
    <>
      <Table grid className={mergeClassNames(className, "w-full table-fixed")}>
        <TableRow>
          <TableHeader rowSpan={2} className="bg-red text-white w-20 align-top">
            <div className="pt-2">
              AASLD<sup>1</sup>
            </div>
          </TableHeader>
          <TableHeader className="text-red text-left">
            <div className="pl-2">Tests</div>
          </TableHeader>
          <TableCell>
            <ul className="ml-4">
              <li>Ultrasound ± AFP</li>
              <li>Every 6 months</li>
            </ul>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableHeader className="text-red text-left">
            <div className="pl-2">At-risk populations</div>
          </TableHeader>
          <TableCell>
            <ol className="list-decimal list-inside ml-2">
              <li>All HBsAg+ persons with cirrhosis</li>
              <li>People with a family history of HCC</li>
              <li>People with hepatitis D virus infection</li>
              <li>HBsAg+ Asian or African American men &gt;40 years of age</li>
              <li>HBsAg+ Asian women &gt;50 years of age</li>
            </ol>
          </TableCell>
        </TableRow>
      </Table>
      <Table
        grid
        className={mergeClassNames(className, "mt-4 w-full table-fixed")}
      >
        <TableRow>
          <TableHeader
            rowSpan={2}
            className="bg-red text-white border-red w-20 align-top"
          >
            <div className="pt-2">
              AATA<sup>8</sup>
            </div>
          </TableHeader>
          <TableHeader className="text-red text-left">
            <div className="pl-2">Tests</div>
          </TableHeader>
          <TableCell>
            <ul className="ml-4">
              <li>
                Ultrasound and AFP<sup>h</sup>
              </li>
              <li>Every 6 months</li>
            </ul>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableHeader className="text-red text-left">
            <div className="pl-2">
              At-risk populations
              <br />
              (Asian Americans)<sup>g</sup>
            </div>
          </TableHeader>
          <TableCell>
            <ol className="list-decimal list-inside mt-2">
              <li>All HBsAg+ people with chronic hepatitis</li>
              <li>People with cirrhosis or family history of HCC</li>
            </ol>
          </TableCell>
        </TableRow>
      </Table>
    </>
  );
};
export default UltrasoundSurveillanceTablesDesktop;
